<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="filteredList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: related project name -->
        <template #cell(user_email)="data">
          <b-link
            :class="{ 'selected-license-id': selectedLicenseId == data.item.id }"
            @click="goToLicense(data.item)"
          >
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 17vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
            
              {{ data.item.user.email }}
            </div>
          </b-link>
        </template>

      <!-- Column: is admin -->
        <template #cell(role)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 3vw;
              height: 100%;
              display: flex;
              align-items: center;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            <div class="text-success" v-if="data.item.user.is_staff">Admin</div>
            <div v-if="!data.item.user.is_staff">User</div>
          </div>
        </template>

        <!-- Column:  Basic -->
        <template #cell(basic_count)="data">
          <b-link>
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 2vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.basic_count }}
            </div>
          </b-link>
        </template>

        <!-- Column:  Plus -->
        <template #cell(plus_count)="data">
          <b-link>
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 2vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.plus_count }}
            </div>
          </b-link>
        </template>

        <!-- Column:  Pro -->
        <template #cell(pro_count)="data">
          <b-link>
            <div
              style="
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 2vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              :class="{
                'dark-color': $store.state.appConfig.layout.skin === 'dark',
              }"
            >
              {{ data.item.pro_count }}
            </div>
          </b-link>
        </template>

        <!-- Column:  Expired date -->
        <template #cell(expired_date)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 5vw;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            {{ formatDate(data.item.expired_date) }}
          </div>
        </template>

        <!-- Column:  Frequency -->
        <template #cell(payment_frequency)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 5vw;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            <div v-if="data.item.payment_frequency == 1">Monthly</div>
            <div v-else>Annually</div>
          </div>
        </template>

        <!-- Column:  Has unlimited licenses -->
        <template #cell(has_unlimited_licenses)="data">
          <div
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 5vw;
            "
            :class="{
              'dark-color': $store.state.appConfig.layout.skin === 'dark',
            }"
          >
            <b-form-checkbox :disabled="true" v-model="data.item.user.has_unlimited_licenses" />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-1"
      size="sm"
      centered
      ok-title="Remove"
      ok-variant="danger"
      title="Are you sure?"
      @ok="sendRequestToRemoveTemplate"
    >
      <div class="d-block">Do you want to remove this project?</div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import useTemplatesList from "./useLicensesList";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { errorMessage } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,

    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    selectedLicenseIdLocal: null,
  }),
  computed: {
    listLicenses() {
      return this.$store.getters["Licenses/getLicenses"];
    },
    filteredList() {
      return this.listLicenses.filter((license) =>
        license.user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedLicenseId() {
      return this.selectedLicenseIdLocal ? this.selectedLicenseIdLocal : store.state.License.license.id
    },
  },
  methods: {
    goToLicense(license) {
      this.selectedLicenseIdLocal = license.id
      this.$store.commit('License/SET_USER_LICENSE', license)

      this.$router.push({
        name: "license-edit",
        params: {
          licenseID: license.id,
        },
      });
    },
    formatDate(date) {
      let formattedDate = new Date(date).toLocaleDateString()
      let splittedDate = formattedDate.split('/')
      return (splittedDate[0] + '-' + splittedDate[1] + '-' + splittedDate[2])
    },
    showModal(id) {
      this.selectedId = id;
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modal-1", "#btnToggle");
    },
    sendRequestToRemoveTemplate() {
      store
        .dispatch("Template/deleteTemplate", { templateID: this.selectedId })
        .then(() => {
          this.selectedId = null;
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully remove this project",
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          errorMessage(this.$toast, err);

          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Something went wrong",
          //     icon: "AlertTriangleIcon",
          //     variant: "danger",
          //     text: err.response.data?.detail
          //       ? err.response.data?.detail
          //       : "Please try again",
          //   },
          // });
        });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    store.dispatch("Licenses/GET_ALL_LICENSES");

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useTemplatesList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dark-color {
  color: #b4b7bd;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
td {
  position: relative !important;
}
.selected-license-id {
  border-top: 1px dashed #fd1c62 !important;
  border-bottom: 1px dashed #fd1c62 !important;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  box-shadow: 0 0 8px 0 rgb(34 41 47 / 20%);

  padding-left: 25px;
}
</style>
