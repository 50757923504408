var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.filteredList,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(user_email)",fn:function(data){return [_c('b-link',{class:{ 'selected-license-id': _vm.selectedLicenseId == data.item.id },on:{"click":function($event){return _vm.goToLicense(data.item)}}},[_c('div',{class:{
              'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
            },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"17vw","height":"100%","display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(data.item.user.email)+" ")])])]}},{key:"cell(role)",fn:function(data){return [_c('div',{class:{
            'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
          },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"3vw","height":"100%","display":"flex","align-items":"center"}},[(data.item.user.is_staff)?_c('div',{staticClass:"text-success"},[_vm._v("Admin")]):_vm._e(),(!data.item.user.is_staff)?_c('div',[_vm._v("User")]):_vm._e()])]}},{key:"cell(basic_count)",fn:function(data){return [_c('b-link',[_c('div',{class:{
              'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
            },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"2vw","height":"100%","display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(data.item.basic_count)+" ")])])]}},{key:"cell(plus_count)",fn:function(data){return [_c('b-link',[_c('div',{class:{
              'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
            },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"2vw","height":"100%","display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(data.item.plus_count)+" ")])])]}},{key:"cell(pro_count)",fn:function(data){return [_c('b-link',[_c('div',{class:{
              'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
            },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"2vw","height":"100%","display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(data.item.pro_count)+" ")])])]}},{key:"cell(expired_date)",fn:function(data){return [_c('div',{class:{
            'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
          },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"5vw"}},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.expired_date))+" ")])]}},{key:"cell(payment_frequency)",fn:function(data){return [_c('div',{class:{
            'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
          },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"5vw"}},[(data.item.payment_frequency == 1)?_c('div',[_vm._v("Monthly")]):_c('div',[_vm._v("Annually")])])]}},{key:"cell(has_unlimited_licenses)",fn:function(data){return [_c('div',{class:{
            'dark-color': _vm.$store.state.appConfig.layout.skin === 'dark',
          },staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","width":"5vw"}},[_c('b-form-checkbox',{attrs:{"disabled":true},model:{value:(data.item.user.has_unlimited_licenses),callback:function ($$v) {_vm.$set(data.item.user, "has_unlimited_licenses", $$v)},expression:"data.item.user.has_unlimited_licenses"}})],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-1","size":"sm","centered":"","ok-title":"Remove","ok-variant":"danger","title":"Are you sure?"},on:{"ok":_vm.sendRequestToRemoveTemplate}},[_c('div',{staticClass:"d-block"},[_vm._v("Do you want to remove this project?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }